import React, { Component } from 'react'
import Portfolio from '../../components/Portfolio/Portfolio' 


export default class PortfolioRoute extends Component {
    render() {
        return (
            <section>
                <Portfolio />
            </section>
        )
    }
}
