import React, { Component } from 'react'
import Landing from '../../components/Landing/Landing' 


export default class LandingRoute extends Component {
    render() {
        return (
            <section>
                <Landing />
            </section>
        )
    }
}
