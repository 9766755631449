import React, { Component } from 'react'
import Contact from '../../components/Contact/Contact' 


export default class ContactRoute extends Component {
    render() {
        return (
            <section>
                <Contact />
            </section>
        )
    }
}
