import React, { Component } from 'react'
import About from '../../components/About/About' 


export default class AboutRoute extends Component {
    render() {
        return (
            <section>
                <About />
            </section>
        )
    }
}
